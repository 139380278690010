<template>
  <div>
    <DrawerProfile
      v-if="showDrawer"
      :showDrawer="showDrawer"
      :onClickClose="() => { showDrawer = false}"
      :profile="profile"
    />
    <a
      class="is-flex navbar-item button-profile button is-rounded is-clipped is-outlined"
      :class="size"
      @click="showDrawer = true"
    >
      <ProfileImage
        class="has-margin-right"
        :class="size === 'is-small' ? 'is-24x24' : 'is-32x32'"
        :size="38"
        :id="profile.Id"
        :image="profile.Image"
      />
      <span v-html="profile.FirstName"></span>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const DrawerProfile = () =>
  import(
    /* webpackChunkName: "topbar" */ '@/components/MainSiteElements/DrawerProfile'
  )
const ProfileImage = () =>
  import(
    /* webpackChunkName: "topbar" */ '@/components/UI/ProfileImage'
  )

export default {
  name: 'logged-in-account',

  props: {
    size: {
      type: String,
      default: ''
    }
  },

  components: {
    ProfileImage,
    DrawerProfile
  },

  data() {
    return {
      showDrawer: false
    }
  },

  computed: {
    ...mapState('profileStore', ['profile']),
  },
}
</script>

<style lang="scss" scoped>
.button-profile {
  padding-left: 0;
}
</style>